@import "../../styles/theme.scss";

.root {
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.rootFitContent {
  width: fit-content;
}

.warn {
  background-color: $cOrangeDim;
  color: $cOrangeDark;
}

.success {
  background-color: $cGreenDim;
  color: $cGreenDark;
}

.error {
  background-color: $cRedDim3;
  color: $cRed;
}

.sSmall {
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
}

.center {
  text-align: center;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;
    height: 14px;
  }
}
