.root,
.noInteraction {
  position: absolute;

  & > div {
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
}

.rootWithDowntime {
  position: absolute;
  border-radius: 5px;
  border-width: 1px;
  display: flex;
  flex-direction: column;

  & > div:not(.downtime) {
    flex: 1;
    position: static !important;
    width: 100% !important;
  }
}

.noInteraction {
  pointer-events: none;
}
