@import "../../../../../../styles/theme.scss";

.wrapper {
  display: inline-flex;

  .btnDelete {
    color: $cRed;
  }

  .icon {
    display: flex;
    align-items: center;
    height: 17px;
  }

  .btnContent {
    display: flex;
    gap: 5px;
  }
}
