.expandParent {
  padding: 4px 20px;
  border-radius: 0;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.expandedRow {
  color: #667680;
  font-size: 14px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  ul {
    list-style-type: none;

    li {
      background: white;
      border-bottom: 1px solid rgb(230, 233, 235);

      a {
        color: #667680;
        display: block;
        padding: 15px 35px;
      }
    }

    li:hover {
      background: #f7fbfd;
      cursor: pointer;
    }
  }
}

.expandButton {
  border: none;
  background: none;
  font-size: 15px;
}

.expandSign::before {
  font-size: 27px;
  content: "+";
  display: inline-block;
}

.expandSign.active::before {
  content: "−";
}

.tableContainer {
  width: 100%;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #667680;
}

.reportTypeSelect {
  min-width: 150px;
  max-width: 36px;

  div {
    max-height: 36px;
  }
}

.tableHeaderColumn {
  min-width: 200px;
}

.detailsButton {
  color: #188bf6;
  text-decoration: none;
}

.infoButton {
  padding-top: 3px;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.headerActions {
  display: flex;
  gap: 8px;
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
}

.titleBox {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
