.reportsContainer {
  column-count: 2;
  font-family: "Lato", sans-serif;
}

.container {
  width: 100%;
  font-size: 13px;
  color: black;
  background: white;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 8px;
}

.reportTable {
  width: 100%;
  font-size: 13px;
}

.headTable {
  color: black;
  border-bottom: 1px solid #dddddd;

  th {
    padding: 15px;
    white-space: nowrap;
  }
}

.alignRight {
  text-align: right;
}

.tableData {
  padding: 7px 15px;
}

.negativeTableData {
  padding: 7px 30px;
}

.totalTable {
  font-weight: bold;
  padding-bottom: 15px;
}

table a.newTabOpen {
  cursor: pointer;
  color: #188bf6;
  text-decoration: none;
}

.reportTitle {
  font-size: 13px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}
