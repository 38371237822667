@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1200px;
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .note {
    font-size: 12px;
    color: $cGray0;
  }
}
