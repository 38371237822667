.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputs {
  display: flex;
  gap: 8px;
  justify-content: space-between;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: 520px) {
    flex-direction: column;
  }
}
