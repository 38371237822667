.timeWrap {
  display: flex;
  gap: 16px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 16px;
  }
}

.timeInner {
  display: flex;
  flex-direction: column;
}

.timeDesc {
  width: 500px;
  margin-top: 10px;
  font-size: 13px;

  @media screen and (max-width: 1000px) {
    margin-top: 0;
    width: 100%;
  }
}

.time {
  width: 300px !important;
  min-width: 300px !important;

  @media screen and (max-width: 1000px) {
    width: 100% !important;
    min-width: 100% !important;
  }
}
