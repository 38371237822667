@import "../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  gap: 6px;
}

.editBtn {
  border: none;
  background: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $cPrimary;
}

.row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.rootEdit {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.select {
  width: 130px !important;
}
