.modalFooterButton {
  display: flex;
  justify-content: flex-end;
}

.requestInfoFormWrapper {
  display: flex;
  flex-direction: column;
  font-family: lato, sans-serif;
  font-size: 18px;
}
