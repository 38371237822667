@import "../../../../../../styles/theme.scss";

.root {
  overflow-x: scroll;
}

.col {
  width: 200px;
  min-width: 200px;
}

.actions {
  display: flex;
  gap: 8px;

  button {
    width: 30px;
    height: 30px;
  }
}

.conflict {
  color: $cRed;
}
