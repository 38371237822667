.iconsWrapper {
  display: flex;
  gap: 4px;
}

.gfeWrapper {
  display: flex;
  background: white;
  width: 19px;
}
