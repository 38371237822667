.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.authorizedText {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;

  svg {
    color: #228c22;
  }
}
