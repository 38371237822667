.header {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;

  .left,
  .right {
    flex: 2;
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  .left {
    .name {
      font-size: 20px;
      color: #404040;
    }
  }

  .right {
    justify-content: flex-end;
  }

  .center {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.toggleLabel {
  display: inline-block;
  max-width: 100%;
  min-width: 136px;
  width: 136px;
  font-weight: 600;
  word-break: break-word;
}

span.toggleLabel {
  color: #404040;
}

.calendarWrapper {
  overflow-x: scroll;
  overflow-y: scroll;
  height: 100%;
}
