.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 0;
  margin-bottom: 10px;

  h4 {
    white-space: nowrap;
  }

  @media screen and (max-width: 910px) {
    flex-direction: column;
  }
}

.right {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;

  @media screen and (max-width: 727px) {
    flex-direction: column;
    align-items: center;
  }
}
