.setting-textarea-box {
  overflow: auto !important;
}

.procedure-notes-container.time-line {
  width: 90% !important;
  max-width: 1280px !important;
  padding: 24px !important;
}

.procedure-notes-list {
  display: block !important;
  padding: 8px !important;
  font-size: 13px !important;
  color: #444444 !important;
  text-decoration: none !important;
  cursor: pointer !important;

  &:hover {
    background: #f5f5f5;
    color: #188bf6;
  }
}

.note-content {
  font-size: 13px;
  line-height: 1.6;
  color: rgb(102, 118, 128);
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;

  p:not(:has(h1, h2, h3, ul, ol)) {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  h1 {
    font-size: 16px;
    color: #1b4368;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
  }

  h2 {
    font-size: 15px;
    color: #1b4368;
    font-weight: 500;
    margin: 1.25rem 0 0.75rem;
  }

  strong {
    color: #1b4368;
    font-weight: 500;
  }

  ul,
  ol {
    margin: 0.5rem 0 1rem;
    padding-left: 1.25rem;
    white-space: normal;
  }

  li {
    margin-bottom: 0.5rem;
    line-height: 1.6;
    position: relative;
    padding-left: 0.25rem;

    &::marker {
      color: #1b4368;
    }
  }

  p {
    margin-bottom: 0.75rem;
    line-height: 1.6;
  }

  h1:first-child {
    margin-top: 0;
  }

  h1 + h2 {
    margin-top: 0.75rem;
  }

  ul:last-child {
    margin-bottom: 1.5rem;
  }

  a {
    color: rgb(102, 118, 128);
    text-decoration: underline;

    &:hover {
      color: #188bf6;
    }
  }

  code {
    background-color: rgba(102, 118, 128, 0.1);
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
    font-size: 14px;
    color: rgb(102, 118, 128);
  }

  blockquote {
    border-left: 2px solid rgb(102, 118, 128);
    margin: 0.75rem 0;
    padding-left: 1rem;
    color: rgb(102, 118, 128);
    font-style: italic;
  }
}

.add-note-label {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
  padding-bottom: 14px;
  border-bottom: 1px solid #e0e0e0;
}

.new-field-label {
  color: #666;
  font-size: 13px;
  margin-bottom: 8px;
  font-weight: 500;
}

.maxCharacterInstruction {
  color: #666;
  font-size: 12px;
  margin-top: 8px;
}

form#add-notes {
  margin-top: 24px;

  .setting-field-outer {
    margin-bottom: 16px;
  }

  .button-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.75rem;
    margin-top: 1.125rem;
    max-width: 350px;

    .cancel-button,
    .save-button {
      height: 42px;
      border-radius: 50px;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      width: 100%;
      padding: 0 24px;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .cancel-button {
      border: 1px solid #4f5266;
      background: #fff;
      color: #1e293b;
      order: 2;

      &:hover:not(:disabled) {
        border-color: #188bf6;
        background-color: rgba(24, 139, 246, 0.04);
      }
    }

    .save-button {
      border: none;
      background: #188bf6;
      color: #fff;
      order: 1;

      &:hover:not(:disabled) {
        background-color: darken(#188bf6, 5%);
      }
    }
  }
}
