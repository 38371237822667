.markGfeButton {
    background: none;
    border: none;
    width: 100%;
    text-align: left;
}.activityDetail {
    padding-right: 15px;
    font-size: 13px;
}

.markdownContent {
    h1 {
        font-size: 16px;
        color: #1b4368;
        font-weight: 600;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #eee;
    }

    h2 {
        font-size: 15px;
        color: #1b4368;
        font-weight: 500;
        margin: 1.25rem 0 0.75rem;
    }

    ul {
        margin: 0.5rem 0 1rem;
        padding-left: 2rem;
    }

    li {
        margin-bottom: 0.5rem;
        line-height: 1.6;
        position: relative;
        padding-left: 0.25rem;
        color: rgb(102, 118, 128);

        &::marker {
            color: #1b4368;
        }
    }

    p {
        margin-bottom: 0.75rem;
        line-height: 1.6;
        color: rgb(102, 118, 128);
    }
} 