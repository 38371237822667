.footer {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selectAll {
  margin-top: 8px;
}
