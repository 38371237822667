@import "../../../styles/theme.scss";

.signBlock {
  display: flex;
  gap: 5px;
  padding: 20px 0;
  justify-content: flex-end;
}

.signText {
  color: $cPrimaryDark;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

.completeBlock {
  display: flex;
  gap: 5px;
  align-items: center;
}

.bottomBar {
  display: flex;
  gap: 10px;
}

.requestModalLabel {
  font-weight: bold;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: lato, sans-serif;
}

.modalFooterButton {
  display: flex;
  justify-content: flex-end;
}

.hederActions {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 11px;
  align-items: center;
}

@media (max-width: 375px) {
  .signBlock {
    justify-content: center;
  }
}
