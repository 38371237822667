@import "../../styles/theme.scss";

.content {
  margin-top: 8px;
}

.placeholder {
  display: flex;
  align-items: center;
  color: white;
  gap: 8px;
}

.placeholderRecording {
  display: flex;
  align-items: center;
  color: $cRed;
  gap: 8px;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $cRed;
}

.valueWrap {
  display: flex;
  gap: 8px;
  font-weight: bold;
}

.mt5 {
  margin-top: 5px;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.flexRowCenter {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.noteActions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 16px;
}

.saveButton,
.cancelButton {
  padding: 8px 24px;
  border-radius: 50px;
  min-width: 100px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 16px;
  background-color: #188bf6;
  border: 1px solid #188bf6;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: #188bf6;
    border-color: #188bf6;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@media (max-width: 768px) {
  .noteActions {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.chartSmartButton {
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;

    svg {
      opacity: 0.6;
    }
  }
}

.noteContainer {
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.noteText {
  font-family: inherit;
  font-size: 14px;
  color: #101828;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.noteSection {
  margin-top: 24px;
}

.noteTitle {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 16px;
}
