.root {
  cursor: pointer;
  margin-top: 25px;
  border: none;
  background: none;
  outline: none;
}

.rootNoMargin {
  margin-top: 0;
}
