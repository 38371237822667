.root {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  width: 100%;
  z-index: 10;

  .inner {
    height: 100%;
    width: 100%;
    opacity: 0.4;
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  align-items: flex-start;

  svg {
    width: auto;
    height: 100%;
    max-height: 16px;

    &.noDoubleBook {
      color: red;
      background-color: white;
      border-radius: 50%;
    }
  }
}

.clean > .inner {
  background-position: 0 0, 5px 5px;
  background-size: 10px 10px;
}

.prep > .inner {
  background-size: 5px 5px;
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .tooltipDoubleBook {
    color: red;
  }
}
