@import "../../../../styles/theme.scss";

$textarea-bg: #f6f7f7;
$modal-content-bg: #f8fafc;
$text-dark: #1e293b;
$bg-light: #f8fafc;
$selected-blue: #2c3e50;
$border-dark: #4f5266;

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(41, 47, 55, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: $cWhite;
  width: 35%;
  min-width: 600px;
  border-radius: 3rem;
  box-shadow: 0 0.5rem 2rem rgba($cBlack, 0.15);
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  padding: 2.5rem 2rem;
  font-size: 1.75rem;
  font-weight: 500;
  color: $text-dark;
  text-align: center;
  font-family: $primaryFont;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.modalContentInner {
  background: $textarea-bg;
  margin: 0 2rem 2rem;
  padding: 2rem;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
}

.customPromptContainer {
  background: $cWhite;
  border-radius: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.customPromptHeader {
  h2 {
    font-family: $primaryFont;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: $selected-blue;
    margin: 0 0 1rem 0;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.customPromptInputWrapper {
  width: 100%;
}

.customPromptInput {
  width: 100%;
  height: 13.75rem;
  padding: 1.5rem;
  background: $textarea-bg;
  border: 1px solid $cGray3;
  border-radius: 1rem;
  resize: none;
  font-size: 1.125rem;
  font-family: $primaryFont;
  color: #64748b;
  margin: 0;
  transition: border-color 0.2s ease;

  &::placeholder {
    color: #94a3b8;
  }

  &:focus {
    outline: none;
    border-color: $cPrimary;
  }
}

.templateOptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

%button-base {
  height: 5.5rem;
  border-radius: 1.5rem;
  font-family: $primaryFont;
  font-size: 1.375rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 100%;
  padding: 0 1.5rem;
}

.templateButton {
  @extend %button-base;
  border: 1px solid $cGray3;
  background: $cWhite;
  color: $text-dark;
  line-height: 24px;
  text-align: center;

  &:hover {
    border-color: $cPrimary;
    background-color: rgba($cPrimary, 0.04);
  }
}

.templateButtonSelected {
  background-color: $selected-blue;
  color: $cWhite;
  border-color: $selected-blue;

  &:hover {
    background-color: darken($selected-blue, 5%);
    border-color: darken($selected-blue, 5%);
  }
}

.saveOption {
  :global {
    .root {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      margin-bottom: 0.75rem;
    }

    .label {
      font-family: $primaryFont;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: $text-dark;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .checkMark {
      width: 1rem;
      height: 1rem;
      border: 1px solid $cGray3;
      border-radius: 0.25rem;
      background: $cWhite;
      position: relative;
      cursor: pointer;
      transition: all 0.2s ease;

      &.checked {
        background-color: $cPrimary;
        border-color: $cPrimary;

        &::after {
          content: "";
          position: absolute;
          left: 0.3125rem;
          top: 0.125rem;
          width: 0.25rem;
          height: 0.5rem;
          border: solid $cWhite;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
        }
      }

      &:hover:not(.checked) {
        border-color: $cPrimary;
      }
    }
  }
}

.modalActions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.75rem;
  margin-top: 1.125rem;
}

.cancelButton,
.saveButton {
  @extend %button-base;
  height: 4.25rem;
  border-radius: 3.125rem;
  font-weight: 600;
  font-size: 1.25rem;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.cancelButton {
  border: 1px solid $border-dark;
  background: $cWhite;
  color: $text-dark;

  &:hover:not(:disabled) {
    border-color: $cPrimary;
    background-color: rgba($cPrimary, 0.04);
  }
}

.saveButton {
  border: none;
  background: $cPrimary;
  color: $cWhite;

  &:hover:not(:disabled) {
    background-color: darken($cPrimary, 5%);
  }
}
