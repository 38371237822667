@import "../../../../../../../styles/theme.scss";

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin: 0;
    color: $cGray0;
  }
}
