@import "../../../styles/theme.scss";

.tabs {
  margin-bottom: 16px;
}

.procedureTabsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media screen and (max-width: 670px) {
    flex-direction: column;
    gap: 0;
    margin-bottom: 16px;
  }
}

.tableHead {
  font-size: 13px;
}

.tableDate {
  color: $cGray1;
  font-size: 13px;
}

.procedureHeader {
  display: flex;
  gap: 4px;
  align-items: center;
}

.tableHeadCentered {
  text-align: center;
}

.tableActionHead {
  padding-left: 20px !important;
}

.backBtn {
  text-align: right;
  margin-top: -47px;
  margin-right: 7px;
  color: #188bf6;
  font-size: 15px;
  margin-bottom: 22px;
  font-weight: bold;
  cursor: pointer;
}

.link {
  color: inherit;
  text-decoration: none;
}
