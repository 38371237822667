@import "../../../../../../styles/theme.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  gap: 16px;
  justify-content: space-between;

  & > * {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.footerRow {
  display: flex;
  gap: 16px;
  justify-content: space-between;

  & > *:first-child {
    flex: 2;
  }

  & > *:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.days {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  button {
    border: 1px solid $cGray2;
    outline: none;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: $cWhite;
    transition: all 0.3s ease;

    &:hover {
      border-color: $cPrimary;
    }

    &.active {
      background-color: $cPrimary;
      color: $cWhite;
      border-color: $cPrimary;
    }
  }
}
