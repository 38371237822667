@import "../../../../styles/theme.scss";

.arrowsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 25px;
  margin-top: 50px;
}

.btnWrapper {
  display: flex;
  align-items: center;
}

.arrowBtn {
  border: none;
  border-radius: 50%;
  background: none;
  border: 2px solid #b3a9a9;
  padding: 15px;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  display: flex;
  scale: 2;
  color: $cGray0Dark;
}

.arrowMirrored {
  transform: rotate(180deg);
}

.arrowLeft {
  position: fixed;
  top: 50%;
}

.arrowRigth {
  position: fixed;
  top: 50%;
  right: 30px;
}
