@import "../../../../../../styles/theme.scss";

.wrapper {
  display: flex;
  gap: 35px;
  flex-direction: column;

  .modalText {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    color: $cGray0Dark;
  }

  .btnSelect {
    max-width: 200px;
    margin-left: 0;
  }
}