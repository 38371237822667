@import "../../../../../../../../styles/theme.scss";

.root {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  color: $cRed;
}
