@import "../../../../../../../../styles/theme.scss";

.root {
  padding-top: 10px;
}

.label {
  color: $cPrimary;
  font-size: 13px;
}
