@import "../../styles/theme.scss";

.bottomBar {
  display: flex;
  gap: 10px;
}

.completeBlock {
  display: flex;
  gap: 5px;
  align-items: center;
}

.signBlock {
  display: flex;
  gap: 5px;
  padding: 20px 0;
  justify-content: flex-end;
}

.signText {
  font-size: 16px;
  color: $cPrimaryDark;
  font-family: "Lato", sans-serif;
}

.hederActions {
  display: flex;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 11px;
  align-items: center;
}

@media (max-width: 375px) {
  .signBlock {
    justify-content: center;
  }
}
