.selectMembership {
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 4px;
  display: block;
}

.membershipSelect {
  width: 230px;
}

.checkbox {
  margin-bottom: 7px;
}
